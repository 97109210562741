.faq{
    height: auto;
    min-height: 700px;
    width: 100vw;
    padding: 150px 10% 50px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    
    &__titles{
        width: 225px;
        margin-right: 50px;

        h3{
            font-family: Poppins;
            font-size: 21px;
            font-weight: 500;
            line-height: 1.52;
            letter-spacing: -0.19px;
            color: #525f7f;
        }

        h2{
            font-family: Poppins;
            font-size: 42px;
            line-height: 42px;
            letter-spacing: -0.13px;
            color: #1970ff;
        }
    }

    &__questions{
        flex: 1;
        min-width: 500px;

        .faq__head-wrapper{
            min-height: auto;
            margin-bottom: 20px;
            box-shadow: none !important;
            overflow: hidden;
            .faq__panel-head{
                padding: 0 30px;
                height: 50px;
                border-radius: 25px;
                border: solid #dee1ed 1px;
                p{
                    font-family: Poppins;
                    font-size: 18px;
                    letter-spacing: -0.14px;
                    color: #525f7f;
                }
                
                .MuiSvgIcon-root{
                    color: #525f7f;
                }
            }
            &.Mui-expanded{
                min-height: 0 !important;
                .faq__panel-head{
                    background-image: linear-gradient(93deg, #20b0f1 7%, #012cff 109%);
                    border: solid transparent 1px;
                    p{
                        color: #ffffff;
                    }
                    
                    .MuiSvgIcon-root{
                        color: white;
                    }
                }
            }
            .faq__panel-content{
                display: block;
                padding: 30px 10px;
                p{
                    margin: 0;
                    padding: 0;
                    margin-bottom: 10px;
                    font-family: Poppins;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 1.65;
                    letter-spacing: -0.16px;
                    color: #525f7f;
                }
            }
        }
    }
}

.MuiExpansionPanelSummary-root.Mui-expanded{
    min-height: 0 !important;
}

@media (max-width: 767px) {
    .faq{
        height: auto;
        min-height: 100vh;
        width: 100vw;
        padding: 100px 10px 50px;
        
        &__titles{
            width: 100%;
            margin-right: 0;
            margin: 0;
            padding: 10px;
            
            h3{
                font-size: 18px;
            }
            
            h2{
                font-size: 32px;
                line-height: 32px;
            }
        }
        
        &__questions{
            width: 100%;
            min-width: 300px;
            
            .faq__head-wrapper{
                .faq__panel-head{
                    padding: 0 15px;
                    height: auto;
                    border-radius: 100px;
                }
                .faq__panel-content{
                    padding: 10px;
                }
            }
        }
    }
    .MuiExpansionPanelSummary-root.Mui-expanded{
        margin: 5px 0;
    }
}
