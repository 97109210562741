.modal-tu-aval{
    display: flex;
    justify-content: center;
    align-items: center;
    &__content{
        width: 450px;
        min-height: 500px;
        border-radius: 10px;
        box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.2);
        background-color: #ffffff;
        padding: 20px 40px;

        h2{
            font-family: Poppins;
            font-size: 22px;
            font-weight: 300;
            letter-spacing: -0.19px;
            color: #32365d;
        }

        h3{
            font-family: Poppins;
            font-size: 17px;
            font-weight: 300;
            letter-spacing: -0.15px;
            text-align: center;
            color: #32365d;
        }

        .form-control-modal{
            overflow: hidden;

            input{
                border-radius: 4px;
                background-color: #eef2f6;
                color: #51617f;
                font-size: 18px;
                margin-bottom: 2px;
                &.input-error{
                    border: solid red 1px;
                }
            }

            label, p{
                font-family: Poppins;
                font-size: 18px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: -0.19px;
                color: #51617f;
                padding: 0 16px;
                margin-bottom: -2px;
                margin-top: 10px;
                border: solid transparent 1px;
            }

            p{
                color: red;
                font-size: 12px;
                margin: 0;
                padding: 0;
            }

            .input-wrapper{
                display: flex;
                justify-content: space-between;
                align-items: center;

                input{
                    &.phone{
                        flex:2;

                        &-code{
                            flex:1;
                            max-width: 100px;
                            margin-right: 10px;
                        }
                    }
                    &.horario{
                        flex:1;

                        &--left{
                            max-width: 45%;
                            margin-right: 10px;
                        }

                        &--right{
                            max-width: 45%;
                            margin-left: 10px;
                        }
                    }
                }
                span{
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    color: #51617f;
                }
            }
        }

        &.success{
            display: flex;
            justify-content: center;
            align-content: space-between;
            align-items: center;
            flex-direction: column;
            .modal-tu-aval__content__wrapper{
                min-height: 300px;
                h2{
                    font-family: Poppins;
                    font-size: 22px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #32365d;
                    text-align: center;
                    margin: 0 0 30px;
                }
                h3{
                    font-family: Poppins;
                    font-size: 17px;
                    font-weight: 300;
                    letter-spacing: -0.15px;
                    text-align: center;
                    color: #32365d;
                    width: 100%;
                    margin: 0 0 -10px;
                }
                .modal-btn-wrapper{
                    .modal-btn{
                        margin-top: 50px;
                        width: 187px;
                        height: 40px;
                        border-radius: 20px;
                        box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
                        border: solid 1px #1970ff;
                        background-color: #1970ff;
                        color: white;
                        font-weight: bolder;
                        font-family: Poppins;
                        font-size: 14px;
                        font-weight: 500;
                        letter-spacing: -0.14px;
                        text-align: center;
    
                        span{
                            margin-top: -2px;
                        }
                    }
                }
            }
        }
    }


    
    form {
    max-width: 500px;
    margin: 0 auto;
    }

    .form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
    }

    p {
    color: #bf1650;
    }

    p::before {
    display: inline;
    content: "⚠ ";
    }

    input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid white;
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
    }

    .modal-btn-wrapper{
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
    button[type="submit"],
    button[type="button"],
    input[type="submit"] {
        width: 187px;
        height: 40px;
        border-radius: 20px;
        box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #1970ff;
        background-color: #1970ff;
        color: white;
        font-weight: bolder;
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: -0.14px;
        text-align: center;

        span{
            margin-top: -2px;
        }
    }

    button[type="submit"]:hover,
    button[type="button"]:hover,
    input[type="submit"]:hover {
    background-color: darken(#1970ff, 5%);
    }

    button[type="submit"]:active,
    input[type="button"]:active,
    input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
    }

    input:disabled {
    opacity: 0.4;
    }

    input[type="button"]:hover {
    transition: 0.3s all;
    }

    button[type="submit"],
    input[type="button"],
    input[type="submit"] {
    -webkit-appearance: none;
    }

    .App {
    max-width: 600px;
    margin: 0 auto;
    }
}