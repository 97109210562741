.contact-wrapper-margin{
    margin: 100px 0;
}
.contact{
    min-height: 700px;
    padding: 50px 9.4%;
    width: 100vw;
    
    &__wrapper{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    &__form{
        border-radius: 10px;
        padding: 40px;
        margin: 20px 0;
        min-width: 400px;
        height: 550px;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        width: 30%;
        margin-right: 10%;
        h2{
            font-size: 42px;
            font-weight: normal;
            letter-spacing: -0.13px;
            color: #1970ff;
            margin: 0;
            margin-bottom: 20px;
            padding: 0 10px;
            &:last-child{
                margin-bottom: 20px;
            }
        }

        .line{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            &.submit{
                margin-top: 35px;
                text-align: center;
                .btn-primary.contain{
                    background-color: #1970ff;
                    color: white;
                    margin: 0 auto;
                    &:hover{
                        background-color: white;
                        color: #1970ff;
                        border-color: #1970ff;
                    }
                }
            }
            .form-wrapper{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 0 10px;
                label{
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    color: #52607f;
                    margin-top: 30px;
                }
                input, textarea{
                    width: 100%;
                    height: 40px;
                    border-radius: 4px;
                    background-color: #eef2f6;
                    border: none;
                    padding: 0 10px;
                    &.input-error{
                        border: solid red 1px;
                    }
                }
                textarea{
                    height: 70px;
                }

            }
        }
    }

    &__map{
        width: 60%;
        height: auto;
        min-height: 300px;
        border-radius: 10px;
        height: 550px;
        overflow: hidden;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        border: solid 1px white;
        >div{
            width: 100%;
            overflow: hidden;
            height: 100%;

        }

        .contact-map{
            position: relative !important;
        }
    }
}

.wit{
    width: 100% !important;
    margin: auto

}

@media (max-width: 767px) {
    .contact{
        height: auto;
        min-height: 100vh;
        padding: 5px 1%;
        
        &__wrapper{
            
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .contact__form{
                padding: 50px 20px 30px;
                margin: 50px 20px;
                min-width: inherit;
                width: 90%;
                height: auto;
                min-height: 80vh;

                h2{
                    font-size: 42px;
                    margin: 0;
                    margin-bottom: 20px;
                    padding: 0 10px;
                }
    
                .line{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    &.submit{
                        margin-top: 20px;
                        text-align: center;
                    }
                    .btn-primary.contain{
                        background-color: #1970ff;
                        color: white;
                        margin: 0 auto;
                        margin-top: 20px;
                        &:hover{
                            background-color: white;
                            color: #1970ff;
                            border-color: #1970ff;
                        }
                    }
                    .form-wrapper{
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        margin: 0 10px;
                        label{
                            font-size: 18px;
                            font-weight: 300;
                            letter-spacing: -0.19px;
                            color: #52607f;
                            margin-top: 20px;
                        }
                        input, textarea{
                            width: 100%;
                            height: 40px;
                            border-radius: 4px;
                            background-color: #eef2f6;
                            border: none;
                            &.input-error{
                                border: solid red 1px;
                            }
                        }
                    }
                }
            }
    
            .contact__map{
                padding: 0;
                margin: 20px 20px;
                min-width: inherit;
                width: 90%;
                max-height: 200px;
                min-height: inherit;

                .contact-map {
                    max-width: inherit;
                    max-height: inherit;
                }
            }
        }
    }
}