// step zero------------------------
.form-container{

    .step-zero{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        justify-items: center;
        
        h2{
            font-family: Poppins;
            font-size: 48px;
            font-weight: 600;
            letter-spacing: -0.19px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 20px;
        }
        
        h3{
            font-family: Poppins;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: -0.19px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 20px;
        }
        
        .btn-primary{
            font-family: Poppins;
            font-size: 14px;
            font-weight: 500;
            letter-spacing: -0.14px;
            color: #1170f8 !important;
            text-align: center;
            background: white;
            border-radius: 50px;
            border: solid 2px transparent;
            cursor: pointer;
            
            &:hover, &.active{
                color: white !important;
                background: #1170f8;
                border: solid 2px white;
            }
        }
    }
}

@media (max-width: 767px) {
    .step-zero{
        h2{
            font-size: 32px !important;
        }
        
        .btn-primary{
            width: 100%;
            margin-top: 20vh;
        }
    }
}

// step zero------------------------
// ------------------------------------------------------


// step------------------------
.step{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    transition: all ease-in-out 1s;

    &.current-step{
        height: 200px;
        margin-bottom: 4px;
        z-index: 2;
        opacity: 1;
    }
    
    &.prev-step{
        margin-bottom: 200px;
        opacity: 0;
        z-index: 1;
        height: 100px;
    }
    &.step-saving{
        z-index: 2;
    }
    &.next-step{
        margin-bottom: -200px;
        opacity: 0;
        z-index: 1;
        height: 100px;
    }

    &__content{
        margin: 0 100px;
        border-radius: 10px;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        
        &__header{
            border: solid 2px white;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 200px;
            background: white;
            border-radius: 10px;
            
            label, input{
                width: 100%;
                font-family: Poppins;
                font-size: 36px;
                letter-spacing: -0.29px;
                text-align: center;
                color: #32325d;
                top: 0px;
                
                &.MuiInputLabel-shrink{
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #525f7f;
                    transform: translateY(-20px);
                }
            }
            label{
                font-size: 20px;
            }

            
            p{
                font-family: Poppins;
                font-size: 15px;
                font-weight: 300;
                letter-spacing: -0.19px;
                text-align: center;
                color: #d13939;
            }
        }
        &__footer{
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            
            &__wrapper{
                position: absolute;
                width: 35%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;
                height: 90px;
                top: -20px;
                right: -10%;
                height: 90px;
            }

            .btn-primary{
                width: auto;
                padding: 0 25px;
                background-color: #1970ff;
                color: white;
                i{
                    margin-left: 4px;
                }
                &:disabled{
                    background-color: #9b9b9b;
                    border-color: #9b9b9b;
                }
            }

            p{
                font-family: Poppins;
                font-size: 15px;
                letter-spacing: -0.11px;
                color: #525f7f;
            }
        }
    }
}

@media (max-width: 767px) {
    .step{
        min-height: inherit;

        &__content{
            margin: 0;
            border-radius: 0;
            box-shadow: none;
            
            &__header{
                border: none;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 200px;
                background: transparent;
                border-radius: 10px;
                padding: 20px;
                box-shadow: 0 0 5px #999;
                background: white;

                .MuiFormControl-root{
                    min-width: 100%;
                    margin: 0 20px;
                }

                label, input{
                    font-size: 20px;
                    letter-spacing: -0.29px;
                    text-align: center;
                    color: #32325d;
                    top: -10px;
                }
                
                p{
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #d13939;
                }
            }

            &__footer{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;
                margin-top: 160px;
                
                &__wrapper{
                    position: relative;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    flex-direction: column;
                    height: auto;
                    top: inherit;
                    right: 10px !important;
                    bottom: 0;
                    
                }

                .btn-primary{
                    padding: 0 25px;
                    color: white;
                    z-index: 1;
                    width: auto;
                    background-color: #1970ff;
                i{
                    margin-left: 4px;
                }

                    &:disabled{
                        background-color: #9b9b9b;
                        border-color: #9b9b9b;
                    }
                }

                p{
                    font-family: Poppins;
                    font-size: 15px;
                    letter-spacing: -0.11px;
                    color: #525f7f;
                    margin: 0;
                    padding:0;
                }
            }
        }
    }
}
// step------------------------
// ------------------------------------------------------


// step nav------------------------
.step__nav{
    position: absolute;
    width: 100px;
    height: 40vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding-top: 44px;
    
    .form-nav__icon{
        border: 2px solid white;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        margin: 10px;
        color: #1170f8;
        cursor: pointer;
        background: white;
        color: white; 
        border: 2px solid #1170f8;
        transition: all ease-in-out .5s;
        i{
            color: #1170f8;
        }
        
        &:hover{
            background: #1170f8;
            color: white; 
            border: 2px solid #1170f8;
            i{
                color: white;
            }
        }
    }
}

@media (max-width: 767px) {
    .step__nav{
        width: 100px;
        top: 4px;
        left: 0;
        height: 60.5vh;
        bottom: 15px;
        align-items: flex-end;
        flex-direction: row;
        padding-top: 0;
        
        .form-nav{
            z-index: 2;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
// step nav------------------------
// ------------------------------------------------------


// step two------------------------
.step{

    &__content{

        &.rubro{
            box-shadow: none;

            .step__content__header{
                flex-direction: column;

                p{
                    font-family: Poppins;
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #525f7f;
                    margin: 20px;
                    margin-top: 40px;
                }
            }

            .step__content__footer{

                &__wrapper{
                    top: 35px;
                }
            }
        }
    }
    .alice-carousel{

        &__stage{
            height: 160px;
            padding: 10px;
        }

        &__wrapper{

            .img-wrapper{
                width: 140px;
                height: 140px;
                border-radius: 10px;
                box-shadow: 0 0 15px rgba(148, 142, 180, 0.5);
                background-color: #ffffff;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                border: solid 2px white;
                cursor: pointer;
                margin: 0 auto;
                transition: all ease-in-out .5s;
                
                &.active{
                    background: #1170f8;
                    box-shadow: 0 0 5px #1170f8;
                    border: solid 2px #1170f8;
                    img{
                        color: white;
                    }
                    
                    p{
                        color: white;
                    }
                }
                
                img{
                    width: 100%;
                    height: auto;
                    max-width: 80px;
                    margin-bottom: 0;
                    margin-bottom: 40px;
                }
                
                p{
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    text-align: center;
                    margin: 0 0 10px;
                    text-transform: capitalize;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .rubro{
        .step__content__footer__wrapper{
            top: 0px !important;
        }
    }
}
// step two------------------------
// ------------------------------------------------------

// step saving ------------------------
.step-saving{
    height: auto;
    min-height: 40vh;
    .step__content{
        box-shadow: none;
        background: transparent !important;
    }
    .step__content__header{
        background: transparent !important;
        border: none;
        height: auto;
        min-height: 40vh;
        display: flex;
        flex-direction: column;
        padding: 20px;
        
        h2{
            text-align: left;
            margin: 30px 0;
            font-family: Poppins;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: -0.19px;
            color: #32325d;
            width: 100%;
            padding: 0 15px;

            strong{
                font-weight: bolder;
            }
        }
        
        &__wrapper{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-wrap: wrap;
            
            &__column{
                width: 45%;

                .bg-white{
                    height: 220px;
                    border-radius: 10px;
                    box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
                    background-color: #eee;
                    padding: 35px 15px;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: space-between;
                    
                    p{
                        text-align: left;
                        font-family: Poppins;
                        font-size: 15px;
                        font-weight: 300;
                        letter-spacing: -0.16px;
                        color: #525f7f;
                    }
                    h3{
                        font-family: Poppins;
                        font-size: 36px;
                        font-weight: 300;
                        letter-spacing: -0.29px;
                        color: #1970ff;
                    }
                    
                }
            }
        }

        .dots-wrapper{
            display: none;
        }

        &__action{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn-primary{
                width: auto;
                font-family: Poppins;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: -0.14px;
                text-align: center;
                color: #ffffff;
                width: 230px;
                height: 40px;
                border-radius: 20px;
                box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
                border: solid 1px #1970ff;
                background-color: #1970ff;
                margin-top: 60px;
                margin: 40px 20px 0;

                &:last-child{
                    width: 230px;
                    height: 40px;
                    border-radius: 20px !important;
                    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px #1970ff;
                    color: #1970ff;
                    background: white;
                }
            }
        }
    }
}

@media (max-width: 767px) {

    .step-saving{
        min-height: 100vh;
        margin: 0 !important;
        top: 90px;
        border-radius: 10px;
        
        .step__content{
            box-shadow: none;
            background: white !important;
            border-radius: 10px;
        }
        
        .step__content__header{
            min-height: 50vh;
            padding: 5px;
            
            h2{
                font-size: 20px;
            }
            
            &__wrapper{
                min-height: 200px;
                position: relative;
                width: 100%;
                margin-bottom: 30px;
                
                &__column{
                    position: absolute;
                    bottom: 0;
                    width: 100px;
                    width: 100%;
                    
                    .bg-white{
                        min-height: auto;
                        height: auto;
                        padding: 10px;
                        margin: 5px 0;
                        height: 0;
                        overflow: hidden;
                        opacity: 0;
                        transition: all ease-in-out 0.5s;
                        
                        &.active{
                            transition: all ease-in-out 0.5s;
                            opacity: 1;
                            height: 100%;
                            display: block;
                        }

                        p{
                            text-align: left;
                            font-size: 15px;
                            color: #525f7f;
                        }

                        h3{
                            font-size: 36px;
                            color: #1970ff;
                        }

                    }
                    
                }
            }
            .dots-wrapper{
                display: block;
                height: 40px;
                width: 100%;
                text-align: center;
                margin-top: -12px;
                z-index: 3;

                span{
                    width: 12px;
                    height: 12px;
                    border-radius: 20px;
                    background: #ddd;
                    margin: 10px;
                    display: inline-block;
                    cursor: pointer;

                    &.active{
                        background: #ccc;
                    }
                }

            }
            &__action{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin: 5px;

                .btn-primary{

                    width: auto;
                    font-size: 14px;
                    text-align: center;
                    color: #ffffff;
                    width: 100% !important;
                    height: 40px;
                    border-radius: 20px;
                    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
                    border: solid 1px #1970ff;
                    background-color: #1970ff;
                    margin: 5px;
                }
            }
        }
    }
}
// step saving ------------------------
// ------------------------------------------------------


// legal ------------------------
    .legal{
        width: 100vw;
        min-height: 200px;
        padding: 20px 9.4%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: #f1f1f1;
        transition: all ease-in-out 0.5s;

        &.saved{
            min-height: 600px;
            margin-top: -400px;
        }
        
        p{
            font-family: Poppins;
            font-size: 12px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.12px;
            text-align: justify;
            color: #525f7f;
        }
    }

@media (max-width: 767px) {
    .legal{
        
        &.saved{
            min-height: 700px;
            margin-top: -300px;
        }
        
        p{

        }
    }
}
// legal ------------------------
// ------------------------------------------------------
