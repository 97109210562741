.banner{
    &-services{
        margin-top: 100px;
        height: 100vh;
        min-height: 700px;
        background-size: contain;
        background-position: right top;
        padding: 0 10%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    
    &-text{
        width: 50vw;
        h2{
            font-family: Poppins;
            font-size: 48px;
            font-weight: bolder;
            letter-spacing: -0.26px;
            color: #32325d;
            margin-bottom: 30px;
        }
        
        h3{
            font-family: Poppins;
            font-size: 21px;
            font-weight: bolder;
            line-height: 1.52;
            letter-spacing: -0.19px;
            color: #525f7f;
            margin-bottom: 30px;
        }

    }
}

@media (max-width: 767px) {
    .banner-services{
        height: auto;
        min-height: 85vh;
        padding: 20px;
        background-size: cover;
        background-position: -100% 0px;
        margin-top: 50px;
        margin-bottom: 50px;

        .banner-text{
            width: 70vw;
            h2{
                margin: 0;
                span{
                    font-size: 30px;
                    margin-bottom: 10px;
                    display: block;
                }
            }
            
            h3{
                font-size: 18px;
                width: 80vw;
                height: auto;
                margin-bottom: 20px;
            }
        }
    }
}