.nav-scroll .nav-bar-header,
.nav-scroll .nav-bar-header.home-nav{
    .show{
        .navbar-nav{
            .nav-item{
                a{
                    color: white !important;
                }
                &:last-child{
                    a{
                        color: white !important;
                    }
                }
            }
        }
    }
    .navbar-nav{

        .nav-item{
            a{
                color: #525f7f !important;
                border-bottom: 2px transparent solid;
                &:hover{
                    border-bottom: 2px #525f7f solid !important;
                }
            }

            &:last-child{
                a{
                    border-color: #525f7f !important;
                    color: #525f7f !important;
                    background: transparent !important;
                }
            }

            &:last-child:hover{
                a{
                    border-color: #525f7f !important;
                    color:white !important;
                    background: #525f7f !important;
                }
            }
        }
    }
}
.nav-bar-header{
    width: 100%;
    height: 70px;

    &.home-nav{
        .navbar-nav{

            .nav-item{

                a{
                    color: white !important;
                    border-bottom: 2px transparent solid;
                    &:hover{
                        border-bottom: 2px #525f7f solid;
                    }
                }

                &:last-child{
                    a{
                        border-color: white !important;
                        color: white !important;
                        background: transparent !important;
                    }
                }

                &:last-child:hover{
                    a{
                        border-color: white !important;
                        color:#525f7f !important;
                        background: white !important;
                    }
                }
            }
        }
    }

    &.nav-scroll.home-nav, &.nav-scroll{
        .navbar-nav{

            .nav-item{

                a{
                    color: #525f7f !important;
                    border-bottom: 2px transparent solid;
                    &:hover{
                        border-bottom: 2px #525f7f solid !important;
                    }
                }

                &:last-child{
                    a{
                        border-color: #525f7f !important;
                        color: #525f7f !important;
                        background: transparent !important;
                    }
                }

                &:last-child:hover{
                    a{
                        border-color: white !important;
                        color:#525f7f !important;
                        background: white !important;
                    }
                }
            }
        }
    }

    .navbar-nav{

        .nav-item{
            margin: 0 2%;

            &:last-child{
                margin: 0 0 0 2%;
                a{
                    padding: 10px !important;
                    border-radius: 50px;
                    border: solid #525f7f 2px !important;
                    height: 35px;
                    width: 140px;
                    display: flex;
                    align-items: center;
                    margin-top: 5px;
                    justify-content: center;
                    color: #525f7f !important;

                    &.active{
                        font-weight: bold;
                    }
                }

                &:hover{
                    a{
                        border-color: #525f7f !important;
                        color:white !important;
                        background: #525f7f !important;
                    }
                }
            }

            a{
                font-family: Poppins;
                font-size: 18px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.14px;
                color: #525f7f !important;
                text-decoration: none !important;
                border-bottom: 2px transparent solid;
                &:hover{
                    border-bottom: 2px #525f7f solid !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .navbar-toggler.active .toggle-icon:after{
        top: 0;
    }

    .navbar-collapse {
        background-color: rgba(0, 0, 0, 0.98) !important;
    }
    .nav-scroll .nav-bar-header .navbar-nav .nav-item a{
        color: white !important;
    }
    .nav-bar-header{

        .navbar-nav{

            .nav-item{
                margin: 0 2% !important;

                &:last-child{
                    margin: 0 2% !important;
                    a.nav-link{
                        padding: 20px !important;
                        border-radius: 50px;
                        border: none !important;
                        border-color: transparent !important;
                        height: 75px;
                        color: white !important;
                    }

                }

                a.nav-link{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 25px !important;
                    height: 75px;
                    padding: 20px !important;
                    color: white !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .container-fluid,
    .row,
    .fixed-100{
        max-width: 100vw !important;
        padding: 0;
        margin: 0;
    }

    .home-nav .toggle-icon,
    .home-nav .toggle-icon:after,
    .home-nav .toggle-icon:before {
        width: 25px;
        height: 2px;
        content:'';
        border-radius: 1.5px;
        background-color: white;
        transition: .5s;
        position: absolute;
        left: 0;
    }

    .nav-scroll .home-nav .toggle-icon,
    .nav-scroll .home-nav .toggle-icon:after,
    .nav-scroll .home-nav .toggle-icon:before {
        width: 25px;
        height: 2px;
        content:'';
        border-radius: 1.5px;
        background-color: #1970ff;
        transition: .5s;
        position: absolute;
        left: 0;
    }

    .false .home-nav .toggle-icon,
    .false .home-nav .toggle-icon:after,
    .false .home-nav .toggle-icon:before {
        background-color: white !important;
    }

    .nav-scroll .toggle-icon,
    .nav-scroll .toggle-icon:after,
    .nav-scroll .toggle-icon:before
    .false .toggle-icon,
    .false .toggle-icon:after,
    .false .toggle-icon:before {
        background-color:#012cff !important;
    }

    .navbar-toggler.active .toggle-icon:after,
    .navbar-toggler.active .toggle-icon:before {
        background-color: white !important;
    }

    .active .navbar-toggler{
        background-color: transparent !important;
    }

    .navbar-toggler.active .toggle-icon,
    .false .home-nav .active .toggle-icon{
        background: transparent !important;
    }
}
