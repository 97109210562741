.services-home{
    height: auto;
    max-height: 750px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 140px 0 50px 10%;

    
    &__info{
        flex: 2;
        min-width: 250px;
        h3{
            font-family: Poppins;
            font-size: 21px;
            font-weight: 500;
            letter-spacing: -0.19px;
            color: #525f7f;
        }
        
        h4{
            font-family: Poppins;
            font-size: 42px;
            letter-spacing: -0.13px;
            color: #1970ff;
            margin: 0;
            margin-bottom: 15px;
        }
        p{
            font-family: Poppins;
            font-size: 17px;
            font-weight: 300;
            line-height: 1.65;
            letter-spacing: -0.16px;
            color: #525f7f;
            width: 300px;
            margin-top: 30px;
        }
        .slider-controls{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100px;
            span{
                i{
                    cursor: pointer;
                    font-size: 22px;
                    color: #32325d;
                    border: solid 1px #32325d;
                    border-radius: 100%;
                    height: 40px;
                    width: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 20px;
                    font-weight: thin;
                }
            }
        }
    }
    
    &__carousel{
        flex: 5;
        max-width: 70%;
        position: relative;
        padding: 5px;

        .alice-carousel{
            >div{
                padding: 10px;
                .alice-carousel__wrapper{
                    padding-bottom: 10px;
                    .alice-carousel__stage{
                        margin-top: 0px;
                        .alice-carousel__stage-item{
                            height: 430px;
                        }
                    }
                }
            }
        }

        &__blue{
            position: absolute;
            top: -40px;
            left: 0px;
            right: 0;
            height: 200px;
            background-image: linear-gradient(100deg, #20b0f1, #012cff);
        }

        &__item{
            height: 380px;
            background-color: transparent;
            margin: 0 auto;
            width: 260px;

            &__image{
                background-size: cover;
                background-position: center;
                height: 200px;
                border-radius: 20px 20px 0 0;
            }

            &__info{
                background: white;
                padding: 0 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-direction: column;
                border-radius: 0 0 20px 20px;
                box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
                height: 175px;

                h3{
                    font-family: Poppins;
                    font-size: 19px;
                    font-weight: 500;
                    letter-spacing: -0.13px;
                    text-align: center;
                    color: #32325d;
                    margin-top: 20px;
                }
                p{
                    font-size: 18px;
                    font-weight: 300;
                    letter-spacing: -0.1px;
                    text-align: center;
                    color: #525f7f;
                    padding: 5px 18%;
                }
            }
        }
    }
}

.alice-carousel__dots{
    display: none;
}

.alice-carousel__dots{
    &-item {
        width: 10px;
        height: 10px;
    }
}


@media (max-width: 767px) {
    .services-home{
        height: auto;
        max-height: inherit;
        min-height: 110vh;
        padding-left: 1%;
        flex-wrap: wrap;
        padding: 100px 0 0;
        margin-bottom: 40px;
        &__info{
            flex: 1;
            padding: 20px;
            h3{
                font-size: 21px;
                font-weight: 500;
                letter-spacing: -0.19px;
                color: #525f7f;
                margin-bottom: 15px;
            }
            
            h4{
                font-size: 42px;
                letter-spacing: -0.13px;
                color: #1970ff;
                margin: 0;
                margin-bottom: 20px;
            }
            p{
                font-size: 17px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: -0.16px;
                color: #525f7f;
                width: 300px;
            }
            .slider-controls{
                display: none;
            }
        }
        
        &__carousel{
            flex: 1;
            position: relative;
            width: 100%;
            max-width: 600%;
            margin-top: 50px;
            
            .slider-controls{
                position: absolute;
                bottom: 50px;;
                cursor: pointer;
                font-size: 30px;
                color: #32325d;
                border: solid 1px #32325d;
                border-radius: 100%;
                height: 40px;
                width: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                &.next-arrow{
                    left: -180px;
                }
                &.prev-arrow{
                    left: -240px;
                }
            }

            &__blue{
                width: 100vw;
            }

            &__items{
                height: auto;
            }
            .__active{
                z-index: 100;
                .services-home__carousel__item{
                    opacity: 1 !important;
                }
            }
            &__item{
                height: 380px;
                background-color: white;
                min-width: 80vw;
                opacity: 0;
                
                &__image{
                    background-size: cover;
                    background-position: center;
                    height: 200px;
                }

                &__info{
                    h3{
                        font-size: 19px;
                        font-weight: 500;
                        letter-spacing: -0.13px;
                        text-align: center;
                        color: #32325d;
                    }
                    p{
                        font-size: 17px;
                        font-weight: 300;
                        letter-spacing: -0.11px;
                        text-align: center;
                        color: #525f7f;
                    }
                }
            }
        }
        .alice-carousel__stage{
            margin: 0 3%;
            padding: 10px;

            &-item.__active{
                .services-home__carousel__item{
                    border-radius: 20px;
                    // box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
                }
            }
        }
        .alice-carousel__dots{
            position: relative;
            margin: -50px 0 -10px;
            display: block;
            max-width: 100vw;
            z-index: 100;
        }
    }
}