.about-article{
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    h2{
        font-family: Poppins;
        font-size: 21px;
        font-weight: 500;
        line-height: 1.52;
        letter-spacing: -0.19px;
        color: #1970ff;
        text-align: left;
    }
    
    p{
        text-align: left;
        font-family: Poppins;
        font-size: 17px;
        font-weight: 300;
        line-height: 1.65;
        letter-spacing: -0.16px;
        color: #525f7f;
    }
    
    h4{
        text-align: left;
        font-family: LucidaGrande;
        font-weight: normal;
        font-size: 17px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.19px;
        color: #1970ff;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .about-article {
        padding: 0;
    }
}