.about-history{
    padding: 50px 10%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    min-height: 80vh;
    width: 100vw;

    .column50{
        width: 50%;
    }

    &__image{
        display: flex;
        justify-content: center;
        align-items: center;
        
        &__wrapper{
            position: relative;
            img{
                width: 100%;
                padding: 25px;
                max-width: 450px;
                border-radius: 40px;
            }
            .float-div{
                position: absolute;
                bottom: -5px;
                right: 60px;
                width: 280px;
                height: 70px;
                border-radius: 10px;
                box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
                background-color: #ffffff;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-wrapper{
                    width: 70px;
                    height: 70px;
                    border-radius: 10px 0 0 10px;
                    background-image: linear-gradient(130deg, #20b0f1 35%, #012cff 100%);
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i{
                        color: white;
                        font-size: 30px;
                    }
                }

                .text-wrapper{
                    flex: 1;
                    font-family: Poppins;
                    font-size: 15px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: -0.07px;
                    color: #525f7f;

                    p{
                        margin: 0;
                        padding: 0 10px;
                        line-height: 18px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .about-history{
        padding: 20px;
        flex-direction: column-reverse;
        margin-top: -50px;
        
        .column50{
            width: 100%;
            img{
                padding: 0;
                border-radius: 0;
                margin-bottom: 50px;
            }
            .float-div{
                bottom: 20px;
                right: 0;
                left: 0;
                width: 80%;
                margin: 0 10%;
            }
        }
        &__image__wrapper{
            margin: 30px 0;
        }
    }
}