.about-who{
    padding: 50px 10%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    min-height: 80vh;
    width: 100vw;

    .column50{
        width: 50%;
    }

    &__image{
        display: flex;
        justify-content: center;
        align-items: center;
        
        &__wrapper{
            position: relative;
            img{
                width: 100%;
                padding: 25px;
                max-width: 450px;
                border: solid 1px #ddd;
                border-radius: 100%;
            }
            
            &__year{
                position: absolute;
                width: 100px;
                height: 100px;
                box-shadow: 0 0 5px #ddd;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.2);
                background-image: linear-gradient(129deg, #20b0f1, #053dfd, #012cff);
                color: white;
                font-size: 26px;
                &--start{
                    top: 10px;
                    left: 10px;
                }
                &--current{
                    bottom: 10px;
                    right: 10px;
                    background: white;
                    color: #525f7f;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .about-who{
        padding: 20px;

        .column50{
            width: 100%;
            margin: 25px 0;
        }

        &__image{
            
            &__wrapper{
                
                &__year{
                    width: 80px;
                    height: 80px;
                    font-size: 20px;
                    &--start{
                        top: 5px;
                        left: 5px;
                    }
                    &--current{
                        bottom: 5px;
                        right: 5px;
                    }
                }
            }
        }
    }
}