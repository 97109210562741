.btn-primary{
    color: white;
    padding: 10px;
    width: 170px;
    height: 40px;
    border-radius: 20px !important;
    box-shadow: 0 15px 35px 0 rgba(0, 0, 0, 0.1);
    letter-spacing: -0.1px;
    font-size: 14px;
    transition: all ease-in-out .5s;
    display: flex;
    justify-items: center;
    align-items: center;
    
    &:disabled{
        background-color: #ccc;
        border-color: #ccc;
        color: white;
    }
    
    span{
        margin: 0 auto;
        margin-top: 0px;
        font-weight: bolder;
    }
    
    
    &.bordered{
        border: solid 1px white;
        background: transparent;
        
        &:hover{
            box-shadow: none;
            background: white;
            color: #1864bc;
        }
    }
    
    &.contain{
        border: solid 1px #ffffff;
        background: white;
        color: #1864bc;
        
        &:hover{
            box-shadow: none;
            background: #1864bc;
            color: white;
            border: solid 1px white;
        }
    }
}