$base-width: 450px;
$base-width-xs: 320px;
.about-how {
  padding: 50px 10%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-wrap: wrap;
  min-height: 80vh;
  width: 100vw;

  .column50 {
    width: 50%;
    min-width: 400px;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;

    &__wrapper {
      position: relative;
      min-height: $base-width/2;
      width: $base-width;
      .circle-item {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100px;
        width: $base-width;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 20;

        &-img {
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px transparent;
          height: 80px;
          width: 80px;
          max-height: 80px;
          max-width: 80px;
          min-height: 80px;
          min-width: 80px;
          height: auto;
          border-radius: 100%;
          padding: 15px;
          background: white;
          box-shadow: 0 6px 13px 0 rgba(145, 145, 145, 0.3);
          cursor: pointer;
          transition: all ease-in-out 0.5s;
          &:hover {
            background-image: linear-gradient(
              129deg,
              #20b0f1,
              #053dfd,
              #012cff
            );
            box-shadow: 0 0 10px #ddd;
          }
          &.active {
            background-image: linear-gradient(
              129deg,
              #4a8ead,
              #053dfd,
              #012cff
            );
            box-shadow: none;
            transition: all ease-in-out 0.5s;
            border: solid 1px white;
          }
          img {
            width: 100%;
            height: auto;
          }
        }
      }
      .circle-content {
        height: $base-width;
        width: $base-width;
        position: absolute;
        top: -175px;
        left: 0;
        border-radius: 100%;
        padding: 40px;
        z-index: 10;

        &__in {
          height: 100%;
          width: 100%;
          border: solid 1px #eee;
          border-radius: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          h2 {
            font-family: Poppins;
            font-size: 24px;
            font-weight: 500;
            letter-spacing: -0.19px;
            text-align: center;
            color: #32325d;
            text-align: center;
          }

          h4 {
            font-family: Poppins;
            font-size: 17px;
            font-weight: 300;
            letter-spacing: -0.19px;
            text-align: center;
            color: #525f7f;
            text-align: center;
          }
        }
      }
    }
  }

  &__final-image {
    display: block;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    object-fit: contain;
    margin-top: 30px;

    @media (max-width: 480px) {
      max-height: 433px;
    }
  }
}

@media (max-width: 767px) {
  .about-how {
    padding: 5px;
    min-height: 100vh;
    height: auto;
    margin-top: -40px;

    .column50 {
      width: 100%;
      min-width: 100%;
      &.about-how__article {
        padding: 20px !important;
      }
    }

    &__image {
      padding: 30px 0;
      margin-bottom: 40px;

      &__wrapper {
        min-height: $base-width-xs/2;
        width: $base-width-xs;
        .circle-item {
          width: $base-width-xs;

          &-img {
            height: 70px;
            width: 70px;
            max-height: 70px;
            max-width: 70px;
            min-height: 70px;
            min-width: 70px;
            padding: 15px;
          }
        }
        .circle-content {
          height: $base-width-xs;
          width: $base-width-xs;
          top: -110px;
          padding: 30px;

          &__in {
            height: 100%;
            width: 100%;
            border: solid 1px #eee;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h2 {
              font-size: 16px;
            }

            h4 {
              display: none;
            }
          }
        }
      }
    }
  }
}
