.about-banks{
    padding: 50px 10%;
    min-height: 300px;
    width: 100vw;
    margin: 0 0 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f6f9fc;

    .alice-carousel{
        .img-wrapper{
            height: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;  
            img{
                width: auto;
                max-height: 45px;
                height: auto;
            }
        }
        .alice-carousel__dots{
            display: none;
        }
    }
}

@media (max-width: 767px) {
    .about-banks{
        padding: 10px;
        min-height: 200px;
        width: 100vw;
        margin: 0 0 150px;
        
        .alice-carousel{
            min-height: 200px;
            &__wrapper{
                min-height: 150px;
                
                .alice-carousel__stage-item{
                    padding: 40px;
                    &.__active{
                        min-height: 150px;
                        
                        .img-wrapper{
                            min-height: 75px;
                            max-width:100%;
                            img{    
                                min-height: 55px;   
                                max-width: 200px;
                            }
                        }
                    }
                }
            }
            .alice-carousel__dots{
                display: inherit;
                max-width: 90vw;
            }
        }
    }
}