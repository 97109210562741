.subscribe-home{
    height: 160px;
    background-image: linear-gradient(97deg, #20b0f1, #012cff);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10%;
    width: 100%;

    &__title{
        width: 50%;
        h2{
            font-size: 42px;
            font-weight: 600;
            letter-spacing: -0.13px;
            color: #ffffff;
        }
    }
    
    &__form{
        width: 45%;
        form{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .input-rounded{
                width: 260px;
                height: 36px;
                border-radius: 20px;
                box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.25);
                background-color: #ffffff;
                margin-right: 20px;
                padding: 2px 20px;
                font-family: Poppins;
                border: none;
                outline: none;
    
                &.input-error{
                    border: solid red 1px;
                }
            }
            
            .btn-primary.contain{
                width: 120px;
                background: #34c0ff;
                color: white;
                font-weight: bolder;
                font-family: Poppins;
                border: solid 2px transparent;
                transition: all ease-in-out .5s;
                
                &:hover{
                    border: solid 2px white;
                    background: darken(#34c0ff, 20%);
                }
                
                span{
                    margin-top: 0px !important;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .subscribe-home{
        padding: 40px;
        flex-wrap: wrap;
        height: auto;


        &__title{
            width: 100%;
            h2{
                font-size: 32px;
                margin-bottom: 20px;
            }
        }
        
        &__form{
            width: 100%;
            
            form{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
            
            .input-rounded{
                width: 220px;
                height: 36px;
                border-radius: 20px;
                box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.25);
                background-color: #ffffff;
                margin-right: 20px;
                padding: 2px 20px;
                border: none;
                font-family: Poppins;
                margin-bottom: 25px;
            }

            .btn-primary.contain{
                background: #34c0ff;
                color: white;
                font-weight: bolder;
                font-family: Poppins;
            }
        }
    }
}