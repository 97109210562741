.services-warranty-types{
    padding: 100px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    min-height: 400px;

    
    &__list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        border-radius: 10px;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        background-color: #32325d;
        width: 100%;
        
        &__item{
            width: 26%;
            margin: 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-direction: column;
            padding: 30px 20px;
            &__img{
                min-width: 55px;
                min-height: 55px;
                border-radius: 100%;
                box-shadow: 0 0 5px #ddd;
                display: flex;
                justify-content: center;
                align-items: center;
                background: white;
                margin-bottom: 20px;
                img{
                    height: auto;
                    width: 100%;
                    max-width: 40px;
                }
            }
            h3{
                font-family: Poppins;
                font-size: 24px;
                letter-spacing: -0.19px;
                text-align: center;
                color: #ffffff;
            }

            h4{
                font-family: Poppins;
                font-size: 17px;
                font-weight: 300;
                line-height: 1.65;
                letter-spacing: -0.19px;
                text-align: center;
                color: #ffffff;
            }
        }
    }
}

@media (max-width: 767px) {
    .services-warranty-types{
        padding: 20px;
        max-width: 100vw;
        
        &__list{
            
            &__item{
                width: 100%;
                margin: 0;
                &__img{
                    min-width: 55px;
                    min-height: 55px;
                    border-radius: 100%;
                    box-shadow: 0 0 5px #ddd;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: white;
                    margin-bottom: 20px;
                    img{
                        height: auto;
                        width: 100%;
                        max-width: 40px;
                    }
                }
                h3{
                    font-family: Poppins;
                    font-size: 24px;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #ffffff;
                }

                h4{
                    font-family: Poppins;
                    font-size: 17px;
                    font-weight: 300;
                    line-height: 1.65;
                    letter-spacing: -0.19px;
                    text-align: center;
                    color: #ffffff;
                }
            }
        }
    }
}