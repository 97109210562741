.valores-desktop{
    position: absolute;
    bottom: -100px;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    &__item{
        width: 350px;
        height: 200px;
        border-radius: 10px;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        background-color: #ffffff;
    }
}

.valores-desktop__item{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .card{
        height: auto;
        height: 200px;
        width: 320px;
        border-radius: 10px;
        box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
        background-color: #ffffff;
        padding: 25px !important;

        &__image{
            height: 100%;
            display: flex;
            align-items: flex-end;
            height: 100px;
            padding: 20px;
            img{
                max-width: 70px;
                max-height: 70px;
            }
        }
        
        &__texts{
            padding: 0 20px 20px;
            height: 100px;
            h3{
                font-family: Poppins;
                font-size: 19px;
                letter-spacing: -0.13px;
                color: #32325d;
                margin: 5px 0;
                margin-top: -10px;
            }
            p{
                margin: 5px 0;
                font-family: Poppins;
                font-size: 17px;
                font-weight: 300;
                letter-spacing: -0.13px;
                color: #525f7f;
            }
        }
    }
}

@media (max-width: 1366px) {
    .card{
        width: 280px;
        padding: 20px;
        
        &__image{
            height: 70px;
            display: flex;
            align-items: flex-end;
            img{
                max-width: 65px;
                max-height: 65px;
            }
        }
        
        &__texts{
            h3{
                font-size: 18px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media (max-width: 767px) {
    .banner{
        .alice-carousel{
            >div{
                height: 270px;
            }
        }
        .alice-carousel__dots{
            margin: 5px auto !important;
            max-width: 90vw !important;
            width: 100% !important;
            display: block !important;
            margin-top: 4px !important;
    
            &-item {
                width: 10px;
                height: 10px;
            }
        }
    }
    .float-bottom{
        bottom: -235px !important;
    }
    .carousel{
        margin-top: -120px;
        padding: 0 8%;
        &.desktop{
            display: none;
        }
        &.mobile{
            display: inherit;
            .card{
                width: 220px;
                padding: 10px;
                margin-bottom: 30px;
                &__image{
                    height: 50px;
                    img{
                        max-width: 50px;
                        max-height: 50px;
                    }
                }
                
                &__texts{
                    white-space: pre-wrap !important;
                    h3{
                        font-size: 18px;
                    }
                    p{
                        font-size: 16px;
                    }
                }
                ul{
                    bottom: -30px;
                }
            }
        }
    }
}