.footer{
    width: 100vw;
    padding: 0 10%;
    
    &__row{
        display: flex;
        justify-content: space-between;
        height: 100px;
        &:last-child{
            border-top: #ccc 1px solid;
        }
    }
    
    &__logo, &__copy{
        width: 50%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        img{
            width: 130px;
            height: auto;
        }
        a{
            text-decoration: none !important;
            color: #525f7f;
        }
    }
    
    &__nav, &__social{
        width: 40%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        ul{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            margin: 0;
            list-style: none;
            li{
                width: auto;
                text-align: center;
                margin: 0 20px;
                padding: 10px;
                border-radius: 5px;
                &:last-child{
                    margin: 0;
                    margin-left: 20px;
                }
                a{
                    text-decoration: none !important;
                    color: #525f7f;
                    box-sizing:border-box;
                    font-family:-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
                    font-size: 18px;
                    font-weight: bolder;
                    letter-spacing:-0.11px;
                    border-bottom: 2px transparent solid;
                    padding-bottom: 10px;
                    &:hover{
                        border-bottom: 2px #525f7f solid !important;
                    }
                }
            }
        }
    }
    &__social{
        width: 30%;
        ul{
            li{
                min-width: 40px;
                text-align: center;
                margin: 0 2px;
                padding: 5px;
                &:last-child{
                    margin: 0;
                    margin-left: 2px;
                }
                a{
                    i{
                        font-size: 25px;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .footer{
        width: 100vw;
        padding: 50px 1%;
        
        &__row{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: auto;
            &:last-child{
                border-top: #ccc 1px solid;
                flex-direction: column-reverse;
            }
        }
        
        &__logo, &__copy{
            width: 100%;
            justify-content: center;
            align-items: center;
            img{
                width: 130px;
                height: auto;
            }
            a{
                text-decoration: none !important;
                color: #525f7f;
            }
        }
        
        &__nav, &__social{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding: 0;
                margin: 10px;
                list-style: none;
                li{
                    width: auto;
                    text-align: center;
                    margin: 0;
                    padding: 10px;
                    border-radius: 5px;
                    &:last-child{
                        margin: 0;
                        margin-left: 0;
                    }
                    &:hover{
                        background: #fafafa;
                    }
                    a{
                        text-decoration: none !important;
                        color: #525f7f;

                        // color: red;
                        // // overflow: hidden;

                        // &::before {
                        //     content: "";
                        //     position: absolute;
                        //     z-index: -1;
                        //     left: 0;
                        //     right: 100%;
                        //     bottom: 0;
                        //     background: red;
                        //     height: 4px;
                        //     transition: all ease-in-out 0.5s;
                        // }

                        // &:hover,
                        // &:focus,
                        // &:active {

                        //     &:before {
                        //         right: 0;
                        //     }
                        // }
                    }
                }
            }
        }
        &__social{
            width: 30%;
            ul{
                flex-direction: row;
                li{
                    min-width: 40px;
                    text-align: center;
                    margin: 0 2px;
                    padding: 5px;
                    &:last-child{
                        margin: 0;
                        margin-left: 2px;
                    }
                    a{
                        i{
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}