.banner-about{
    height: 100vh;
    min-height: 600px;
    padding: 0 0 0 10%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100vw;
    width: 100vw;
    position: relative;
    
    &__text{
        width: 45vw;

        h2{
            font-family: Poppins;
            font-size: 21px;
            font-weight: bolder;
            line-height: 1.52;
            letter-spacing: -0.19px;
            color: #525f7f;
            margin-bottom: 20px;
        }
        
        h3{
            font-family: Poppins;
            font-size: 48px;
            font-weight: bolder;
            letter-spacing: -0.26px;
            color: #32325d;
            margin-bottom: 20px;
        }
    }
    
    &__image{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 45vw;
        padding: 100px 0;
        text-align: right;
        min-width: 280px;
        
        img{
            width: auto;
            max-width: 40vw;
            max-height: 70vh;
            height: auto;
        }
    }
}

@media (max-width: 767px) {
    .banner-about{
        height: auto;
        min-height: 100vh;
        padding: 100px 20px;
        margin-bottom: 50px;
        position: relative;
        background-image: url(../../../images/about/about-banner.png);
        background-size: cover;
        background-position: center;
        
        &__text{
            width: 100vw;

            h2{
                font-family: Poppins;
                font-size: 20px;
                margin-bottom: 30px;
            }
            
            h3{
                font-size: 35px;
                margin: 0;
                span{
                    margin-bottom: 15px;
                    display: block;
                }
            }
        }
        
        &__image{
            display: none;
        }
    }
}