.banner-calculator{
    width: 100%;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 0 10%;
    background-color: #ccc;
    margin-bottom: -100px;
}

.MuiInput-underline::before{
    border: none !important;
}

.calculator-spy{
    position: relative;
    &__block{
        // height: 100px;
        width: 100%;
        background: white;
    }
}
.calculator{
    display: flex;
    justify-content: center;
    align-items: center;  
    padding: 0 10%;
    width: 100vw;
    min-height: auto;
    background-color: transparent;
    margin-top: 200px;
    margin-bottom: 100px;

    &-bg{
        display: none;
    }
    
    &__container{
        min-height: auto;
        width: 100%;
        position: relative;
        height: 400px;
    }
    
}

@media (max-width: 767px) {
    .banner-calculator{
        min-height: 110vh;
        margin-bottom: 0;
    }
    .calculator-spy__block{
        margin-top: -20vh;
        height: 20px;
        background: transparent;
    }
    .calculator-spy{
        margin-top: 0px;
        min-height: 0;
    }
    .calculator{
        position: relative;
        align-items: flex-start;
        width: 100vw;
        height: auto;
        min-height: 100vh;
        margin-top: 0vh;
        margin-bottom: 0;
        padding: 50px 20px;

        &-bg{
            display: inherit;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 300px;
            background: rgb(255,255,255);
            background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(25,112,255,1) 85%, darken(rgba(25, 113, 255, 0.787), 25%) 100%);
        }

        &__container{
            height: 80vh;
        }

        .step{
            bottom: 200px;
        }
    }
}
  