.services-how-to{
    padding: 100px 10%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    min-height: 100vh;
    width: 100vw;
    
    &__image{
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 10px;
        img{
            border-radius: 100%;
            max-width: 485px;
            width: 100%;
        }
    }
    
    &__text{
        flex: 1;
        height: 100%;
        padding-left: 0;
        h2{
            font-family: Poppins;
            font-size: 21px;
            font-weight: 500;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.52;
            letter-spacing: -0.19px;
            color: #1970ff;
        }
    }
    
    &__card{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: 50px;
        &__icon{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 30px;
            position: relative;
            &::after{
                content: "";
                background: #ddd;
                width: 2px;
                height: 60px;
                position: absolute;
                left: 30px;
                top: -60px;
            }
            &::before{
                content: "";
                background: #ddd;
                width: 2px;
                height: 60px;
                position: absolute;
                left: 30px;
                bottom: -60px;
            }
            span{
                border-radius: 100%;
                padding: 5px;
                box-shadow: 0 0 5px #ccc;
                img{
                    width: 50px;
                    padding: 5px;
                }
            }
        }
        &__texts{
            h3{
                font-family: Poppins;
                font-size: 24px;
                font-weight: 500;
                letter-spacing: -0.19px;
                color: #32325d;
            }
            p{
                font-family: Poppins;
                font-size: 17px;
                font-weight: 300;
                letter-spacing: -0.19px;
                color: #525f7f;
                margin: 0;
            }
        }
    }
}

@media (max-width: 767px) {
    .services-how-to{
        height: auto;
        min-height: 100vh;
        min-width: 100vw;
        padding: 20px;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-bottom: 40px;
        
        &__image{
            img{
                width: 100%;
                height: auto;
            }
        }
        
        &__text{
            flex: 1;
            height: 100%;
            h2{
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.52;
                letter-spacing: -0.19px;
                color: #1970ff;
                margin-top: 20px;
            }
        }
        
        &__card{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 50px;
            &__icon{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-right: 30px;
                position: relative;
                &::after{
                    content: "";
                    background: #ddd;
                    width: 1px;
                    height: 60px;
                    position: absolute;
                    left: 24px;
                    top: -60px;
                }
                &::before{
                    content: "";
                    background: #ddd;
                    width: 1px;
                    height: 60px;
                    position: absolute;
                    left: 24px;
                    bottom: -60px;
                }
                
                img{
                    width: 48px;
                    padding: 10px;
                    border-radius: 100%;
                    box-shadow: 0 0 5px #ccc;
                }
            }
            &__texts{
                h3{
                    font-family: Poppins;
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: -0.19px;
                    color: #32325d;
                }
                p{
                    font-family: Poppins;
                    font-size: 17px;
                    font-weight: 300;
                    letter-spacing: -0.19px;
                    color: #525f7f;
                    margin: 0;
                }
            }
        }
    }
}