.dropzone {
    position: relative;
    /* height: 200px;
    width: 200px;
    background-color: #fff;
    border: 2px dashed rgb(187, 186, 186);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 16px; */
  }
  
  .highlight {
    background-color: rgb(188, 185, 236);
  }
  
  .icon {
    opacity: 0.3;
    height: 64px;
    width: 64px;
  }
  
  .fileInput {
    display: none;
  }
  .upload-image{
    transition: .5s ease;
    backface-visibility: hidden;

  }

  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  .dropzone .full{
    opacity: 1;
    left: 37%;
  }
  .dropzone:hover .middle {
    opacity: 1;
  }
  .dropzone:hover .upload-image{
    opacity: 0.3;
  }