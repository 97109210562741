.width-128{
    width: 128px
}

.container-fluid{
  padding-left: 0;
  padding-right: 0px
}

.width-110{
    width: 110px;
}
.f-r {
    float: right;
}
.toggle-icon span{
  width: 25px;
  height: 2px;
  border-radius: 1.5px;
  background-color: #ffffff;
  display: block;
  margin: 7px 0;
}

.toggle-icon {
  width: 25px;
  height: 2px;
  border-radius: 1.5px;
  background-color: #012cff;
  box-shadow: 0.2 5px rgb(0,0,0,.2);
  transition: .5s;
  position: absolute;


}

.toggle-icon:after,
.toggle-icon:before {
  width: 25px;
  height: 2px;
  content:'';
  border-radius: 1.5px;
  background-color: #012cff;
  box-shadow: 0.2 5px rgb(0,0,0,.2);
  transition: .5s;
  position: absolute;
  left: 0;
}

.toggle-icon:after{
  top:7px
}
.toggle-icon:before{
  top:-7px
}
.navbar-toggler.active .toggle-icon{
  background: rgb(0,0,0,0);
  box-shadow: 0.2 5px rgb(0,0,0,0);
}

.navbar-toggler.active .toggle-icon:after{
  top: 0;
  transform: rotate(135deg)
}
.navbar-toggler.active .toggle-icon:before{
  top: 0;
  transform: rotate(45deg)
}

.alice-carousel__dots-item:hover, .alice-carousel__dots-item.__active{
  background-color: #a5abc5 !important;
}
.alice-carousel__dots-item{
  background-color: #cfd2dd !important;
}
.alice-carousel__dots {
  margin: 5px 0 5px;
  max-width: 90vw;
}
.navbar {
 padding: 0px;
}

.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler{
  background-color: transparent;
  padding: 0;
  width: 50px;
  height: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rubro-img {

    /* width: 260px; */
    height: 200px;
    background-position: center;
    background-size: cover;

}
.rubro-bg {
    width: 260px;
    height: 380px;
    border-radius: 10px;
    box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
    background-color: #ffffff;
}
.title-rubro {
    width: 195px;
    font-family: Poppins;
    font-size: 19px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.13px;
    text-align: center;
    color: #32325d;
}

.descripcin-rubro {
    width: 195px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.11px;
    text-align: center;
    color: #525f7f;
  }

  .edit-image {
      width: 50%;
      display: block;
      margin: auto
  }

  .flex-align-middle{
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: center;
  }

  .bg-client {
    width: 100%;
    height: 150px;
    background-color: #f6f9fc;
  }

  .width-20{
      width: 20%;
  }

  .h-50p{
      height: 50px;
  }

.bg-client .alice-carousel__stage-item{
    text-align: center
}
.title-how-to {
    width: 238px;

    font-family: Poppins;
    font-size: 42px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.13px;
    color: #1970ff;
  }

  .description-how-to{
    width: 276px;
    font-family: Poppins;
    font-size: 17px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.65;
    letter-spacing: -0.16px;
    color: #525f7f;
  }

  .arrow{
    color: #32325d;
    font-weight: bold;
    font-size: 18px;
  }
.how-to{
    background-color: #ffffff;
    padding:15px;
    width: 335px
}
.b-primary{
  width: 40px;
  height: 40px;
  border: solid 1px #32325d;
  padding: 0px
}

.title-howTo {

    font-family: Poppins;
    font-size: 21px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.52;
    letter-spacing: -0.19px;
    color: #525f7f;
  }
.px-15{
    padding: 0 15px;
}

.btn-header{
  border-radius: 17px;
  border: solid 1px #525f7f;
  padding: 2px 21px;
  margin: 7px 0;
}
.home-nav .btn-header{
  border: solid 1px #ffffff;
}

.home{
  background-color: blue;
  width: 100%;
  height: 100vh;
}

.fixed-100{
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.fixed-100.nav-scroll{
  background: white;
  box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
}
.md {
  display: none;

}
@media (min-width: 768px){
  .navbar-expand-md .navbar-collapse {
      display: flex!important;
      flex-basis: auto;
      flex-direction: column;
      align-items: flex-end;
      /* padding-right: 100px; */
  }
  .md{
    display: block
  }
}

@media (max-width: 767px){
  .container-fluid,
  .row,
  .fixed-100{
      max-width: 100vw !important;
      padding: 0;
      margin: 0;
    }

  .container-fluid{
      /* overflow-x: hidden; */
  }
  .xs-p-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-nav .btn-header, .btn-header{
    border: none;
  }
  .nav-bar-header{
    padding: 0 20px;
  }
  .navbar-toggler{
    margin-right: -5px;
  }
}

.user-files-container .btn-group {
    display: inline-flex;
}
