.services-warranty{
    padding: 100px 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    width: 100vw;
    min-height: 400px;

    h2{
        font-family: Poppins;
        font-size: 21px;
        font-weight: 500;
        line-height: 1.52;
        letter-spacing: -0.19px;
        text-align: center;
        color: #1970ff;
    }
    
    &__list{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        
        &__item{
            width: 28%;
            max-height: 110px;
            margin: 30px 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 30px 20px;
            border-radius: 10px;
            box-shadow: 0 15px 35px 0 rgba(148, 142, 180, 0.2);
            background-color: #ffffff;

            i{
                color: white;
                border-radius: 100%;
                height: 40px;
                width: 40px;
                background: #1970ff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                margin-right: 30px;
            }
            h4{
                font-family: Poppins;
                font-size: 23px;
                margin-top: 10px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.15px;
                color: #32325d;
            }
        }
    }
}

@media (max-width: 767px) {
    .services-warranty{
        height: auto;
        min-height: 100vh;
        max-width: 100vw;
        padding: 20px;
        flex-direction: column;
        align-content: center;
        justify-content: center;

        h2{
            font-size: 18px;
            padding: 10px 55px;
            text-align: center;
        }
        
        &__list{
            justify-content: flex-start;
            
            &__item{
                width: 100%;
                max-height: 100px;

                i{
                    min-width: 40px;
                }
                h4{
                    font-size: 18px;
                }
            }
        }
    }
}